<template>
  <div class="activ_wrap container" :class="{'overbg': visible || showGuide || payOk}">
    <h4 class="page-title">
      <img src="./image/txticon.png" />
      <span>618年中大促</span>
      <img src="./image/txticon.png" />
    </h4>
    <div class="subtitle">
      <img src="./image/mobile/subtitle.png" />
    </div>
    <div class="downbox">
      <span class="downtitle">倒计时 :</span>
      <CountDown
        :startTime="startTime"
        :endTime="endTime"
        dayTxt="天"
        hourTxt="时"
        minutesTxt="分"
        secondsTxt="秒" />
    </div>
    <div class="remaining">今日剩余名额：<span>{{5||surplusNum}}</span></div>
    <div class="paybox">
      <div class="payitem" :class="{'active':vipype==idx, 'yearvip':item.use_mode===3}" v-for="(item,idx) in goodList" @click="setViptype(item,idx)" :key="idx">
        <img class="star" src="./image/mobile/star.png" />
        <img class="star2" src="./image/mobile/star2.png" />
        <!-- <img class="star3" v-if="item.use_mode===3" src="./image/mobile/star3.png" /> -->
<!--        <div class="tipbox" v-if="item.use_mode===3">推荐</div>-->
        <span class="pay-title" v-if="idx<2">企业{{idx%2==0 ? '月' : '半年'}}会员</span>
        <span class="pay-title" v-else>个人{{idx%2==0 ? '月' : '半年'}}会员</span>
        <div class="price">
          <span class="icon">¥ </span>
          <span class="price-num">{{item.price}}</span>
        </div>
        <div class="old-price">
          <span class="name">原价：</span>
          <span class="price-num">{{item.origin_price}}元</span>
        </div>
        <!-- <div class="youhui">{{item.down_count=='n'?'不限':item.down_count+'次' }} {{item.down_num}}次/天</div> -->
          <div class="youhui">下载次数：{{item.down_count == 'n' ? '不限':item.down_count}}</div>
          <div class="youhui">单日下载限制：{{item.down_num}}</div>
          <div class="youhui special">有效期限：加赠{{idx%2==0 ? '1个月' : '半年'}}</div>
      </div>
    </div>
    <div class="openvip" @click="openvip">
      <span>立即开通</span>
    </div>
    <div class="activ-rule">
      <div class="title">活动规则</div>
      <ul>
        <li>1、活动时间：2023年6月14日-6月23日；</li>
        <li>2、本站会员素材生成作品需激活授权书方可投放；</li>
        <li>3、用户需于PlayList网站活动页面参与会员套餐优惠抢购；</li>
        <li>4、本活动价格为促销价格，支付后不支持退款及转让；</li>
        <li>5、本活动所有解释权归北京数音未来科技有限公司；</li>
        <li>6、如有疑问请联系客服。</li>
      </ul>
    </div>
    <!--支付弹框-->
    <el-drawer
      :with-header="false"
      :visible.sync="visible"
      direction="btt"
      size="40%"
      :modal-append-to-body="false"
      :before-close="handleClose">
      <div class="draw-title">
        <span class="title">选择支付方式</span>
      </div>
      <ul class="pay-content">
        <li v-for="(item,index) in payTypes" :key="index" @click="curIndex = index, payType = item.value">
          <img :src="item.icon" class="pay_icon" />
          <span :class="{'active': curIndex == index}">{{ item.label }}</span>
        </li>
      </ul>
      <div class="vipbtn" @click="openpay">立即开通</div>
    </el-drawer>
    <!--微信引导页面-->
    <el-drawer
      :with-header="false"
      :visible.sync="showGuide"
      direction="btt"
      size="100%"
      :before-close="handleCloseGuide"
      :modal-append-to-body="false">
      <div class="gride_box" @click="handleCloseGuide">
        <img src="@/assets/image/mobile/jiantou.png" class="jiantou" />
        <div class="guide_title">点击右上角选项按钮</div>
        <div class="browser">
          <img src="@/assets/image/mobile/ios_bro.png" />
          <img src="@/assets/image/mobile/android_bro.png" />
        </div>
        <p class="gride-tip">复制链接后在手机浏览器中打开</p>
        <div class="gride_img">
          <img src="@/assets/image/mobile/guide_icon1.png" />
          <img src="@/assets/image/mobile/guide_icon2.png" />
          <img src="@/assets/image/mobile/guide_icon3.png" />
          <img src="@/assets/image/mobile/guide_icon4.png" />
        </div>
      </div>
    </el-drawer>
    <!--支付成功弹框-->
    <div class="markbg" v-if="payOk">
      <div class="paysuccess">
        <div class="paymark">
          <img class="payicon" src="./image/payok.png" />
          <div class="payok_text">
            <span class="gx">恭喜!</span>
            <span class="txt">您已成为PlayList尊贵的会员</span>
            <span class="findtxt">快去寻找你想要的素材吧～</span>
          </div>
          <div class="payok_btn" @click="closeMark">确 认</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CountDown from "./components/CountDown";
import {fetchPackageList} from '@/api/package';
import {
  getOrderPayByWxPayH5,
  fetchOrderPayStatusByH5,
  sendAlipay,
} from '@/api/mobile';
import { send_eventApi } from '@/utils/common.js';
import { formDateTime } from '@/utils/formDate.js';
import { mapGetters } from 'vuex'
export default {
  components: { CountDown },
  data(){
    return{
      goodList: [],
      payinfo: null,
      startTime: new Date().getTime(),
      endTime: new Date('2023/6/23 23:59:59').getTime(),
      order_no: '',
      // 定时器
      wxPayTimer: null,
      aliPayOrderTimer: null,
      vipype: -1,
      visible: false,
      payTypes: [
        { value: 'weixin', label: '微信支付', icon: require('@/assets/image/mobile/weixin.png') },
        { value: 'Alipay', label: '支付宝支付', icon: require('@/assets/image/mobile/zfb.png') },
      ],
      curIndex: 0,
      payType: 'weixin',
      share_token:'',
      pay_url: '',
      showGuide: false,
      payOk: false,
    }
  },
  computed:{
    ...mapGetters(['userInfo']),
    surplusNum(){
      return Math.floor(Math.random()*(5-1+1))+1;
    }
  },
  created() {
    this.visible = false;
    this.showGuide = false;
    this.share_token =  window.localStorage.getItem('shareMobile_token') || '';
    // this.surplusNum = window.localStorage.getItem('Mobile_surplusNum') || 5;
    this.getPackageList();

    if(window.localStorage.getItem('order_no')){
      let order_no = window.localStorage.getItem('order_no');
      this.handleViewOrderPayWxStatus(order_no);
    }
  },
  methods:{
    // 获取套餐列表
    async getPackageList(){
      const {data} = await fetchPackageList({
        order_id: '',
        goods_type: 2,
        active_type: 2
      });
      if(data && data[1]){
        let result = data[2].concat(data[1])
        this.goodList = result;
        this.vipype = 1;
        this.payinfo = result[this.vipype];
        // this.getPayQrCode();
      }
    },
    setViptype(item,idx){
      this.vipype = idx;
      this.payinfo = item;
    },
    // 打开弹框
    openvip(){
      this.visible = true;
      this.getPayInfo();
    },
    // 获取微信支付信息(默认微信支付)
    async getPayInfo(){
      const res = await getOrderPayByWxPayH5({
        order_id: '',
        goods_id: this.payinfo.goods_id,
        share_token: this.share_token
      });
      if(res.code == 0){
        this.order_no = res.data.order_no;
        this.pay_url = res.data.pay_url;
        this.handleViewOrderPayWxStatus(this.order_no);
      }
    },
    // 微信订单支付状态
    handleViewOrderPayWxStatus(order_no){
      fetchOrderPayStatusByH5({
        order_no: order_no,
        share_token: this.share_token
      }).then((res)=>{
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (res.data.status === -1) {
          // 添加定时器
          let that = this;
          this.wxPayTimer = setTimeout(() => {
            that.handleViewOrderPayWxStatus(order_no, name)
          }, 2000)
        } else if (res.data.status === 0) {
          this.payOk = true;
          // this.$store.dispatch('mobile/setDialogSuccess', true);
          // window.localStorage.setItem('Mobile_surplusNum', this.surplusNum < 2 ? 1 : this.surplusNum -1);
          window.localStorage.setItem('order_no', "");
          // this.$router.push({
          //   path: '/home'
          // });
          this.sendEventapi();
          this.handleClearCountDown();
        }
      });
    },
    handleClose(){
      this.visible = false;
      this.handleClearCountDown();
    },
    // 引导弹框关闭
    handleCloseGuide(){
      this.showGuide = false;
    },
    // 支付
    openpay(){
      let ua = navigator.userAgent.toLowerCase();
      if(!this.userInfo){
        this.$router.push('/login');
        return;
      }
      // 微信内打开，则显示引导弹框
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.showGuide = true;
      }else{
        this.sendPay();
      }
    },
    // 清除定时器
    handleClearCountDown() {
      if (this.wxPayTimer) {
        clearTimeout(this.wxPayTimer);
        this.wxPayTimer = null;
      }
    },
    // 发起支付 微信/ 支付宝
    async sendPay(){
      if(this.payType == 'weixin'){
        window.location.href = this.pay_url;
      } else {
        const res = await sendAlipay({
          order_id: '',
          goods_id: this.payinfo.goods_id,
          share_token: this.share_token
        });
        if(res.code == 0){
          let form = res.data.pay_url;
          const div = document.createElement('div');
          div.innerHTML = form;
          document.body.appendChild(div);
          document.getElementById('alipaysubmit').submit();
        }
      }
      window.localStorage.setItem('order_no', this.order_no);
    },
    // 埋点
    sendEventapi(){
      const obj = {
        package_name: this.payinfo.name,
        package_type: '企业',
        pay_type: '在线支付（支付宝/微信）',
        op_date: formDateTime(),
        m_name: '',
        mid: '',
        p_origin: '手机等移动设备',
        reg_date: this.payinfo.create_at,
        user_name: this.userInfo.name,
        user_id: this.userInfo.user_id
      }
      send_eventApi('e_pay', obj)
    },
    // 关闭支付成功弹框
    closeMark(){
      this.payOk = false;
      // this.$router.push({
      //   path: '/home'
      // });
    }
  }
}
</script>
<style scoped lang="scss">
*{
  padding: 0;
  margin: 0;
  font-style: normal;
}
img{
  display: inline-block;
  height: 100%;
}
.activ_wrap{
  width: 100vw;
  min-height: 100vh;
  user-select: none;
  background: url("./image/mobile/bg.png") no-repeat;
  background-size: 100% 100%;
  .page-title{
    width: 100%;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      height: 12px;
      margin: 0 12px;
    }
    span{
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 12px;
      letter-spacing: 4px;
    }
  }
  .subtitle{
    width: 100%;
    height: 158px;
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .downbox{
    width: 320px;
    height: 25px;
    background: url("./image/mobile/downbg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 12px;
    letter-spacing: 2px;
    .downtitle{
      display: inline-block;
      margin-right: 5px;
    }
  }
  .remaining{
    width: 100%;
    text-align: center;
    margin-top: 23px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 28px;
  }
  .paybox{
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;
    box-sizing: border-box;
    background: rgba(255,146,110,0.5);
    border-radius: 12px;
    border: 1px solid #fff;
    padding: 14px 6px 18px 6px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .payitem{
      width: 148px;
      height: 198px;
      background: url("./image/mobile/paybg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(2n+1){
        margin: 0 3px;
      }
      .star{
        position: absolute;
        left: 5px;
        top: 5px;
        width: 23px;
        height: 23px;
      }
      .star2{
        position: absolute;
        left: 25px;
        top: 7px;
        width: 12px;
        height: 12px;
      }
      .pay-title{
        display: inline-block;
        width: 95%;
        padding-top: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin: 0 auto;
        text-align: center;
      }
      .price{
        width: 95%;
        margin: 0 auto;
        margin-top: 8px;
        text-align: center;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 24px;
        color: #F60002;
        //background: linear-gradient(135deg, #FF6D62 0%, #F60002 100%);
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
        .icon{
          font-size: 12px;
          display: inline-block;
          margin-right: 5px;
        }
        .price-num{
          font-size: 20px;
        }
      }
      .old-price{
        width: 95%;
        margin: 0 auto;
        margin-top: 4px;
        text-align: center;
        .name{
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.25);
          line-height: 12px;
        }
        .price-num{
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(239,73,66,0.75);
          line-height: 12px;
          text-decoration:line-through;
        }
      }
      .youhui{
        width: 95%;
        margin: 0 auto;
        margin-top: 16px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51,51,51,0.6);
        line-height: 12px;
        text-align: center;
      }
      .special{
        color: rgba(239,73,66,0.75);
      }
    }
    .yearvip{
      .star3{
        position: absolute;
        left: 0;
        top: 0;
        width: 36px;
        height: 38px;
      }
      .tipbox{
        width: 40px;
        height: 29px;
        background: url("./image/mobile/tip.png") no-repeat;
        background-size: cover;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
        position: absolute;
        right: -6px;
        top: -9px;
      }
    }
    .active{
      &:after{
        content: "";
        width: 52px;
        height: 3px;
        background: linear-gradient(90deg, #FFFBD8 0%, #FFE2A5 47%, #FFC56A 100%);
        box-shadow: 0px 3px 5px 0px rgba(255,43,30,0.7);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .openvip{
    width: 268px;
    height: 48px;
    background: linear-gradient(180deg, #FFFBD8 0%, #FFE2A5 47%, #FFC56A 100%);
    box-shadow: 0px 4px 7px 0px rgba(255,47,15,0.36);
    border-radius: 25px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #C52203;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 40px;
  }
  .activ-rule{
    width: 100%;
    margin-top: 24px;
    padding-bottom: 54px;
    .title{
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 18px;
      letter-spacing: 4px;
      text-align: center;
    }
    ul{
      width: 100%;
      padding: 0 40px;
      box-sizing: border-box;
      margin-top: 20px;
    }
    li{
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
    }
  }
}
.overbg{
  height: 100vh;
  overflow: hidden;
}
.draw-title {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  .title{
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: - 38px;
      width: 30px;
      height: 2px;
      background: linear-gradient(90deg, #E52A0D 0%, rgba(255, 92, 34, 0.2) 100%);
    }
    &:after{
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right:-38px;
      width: 30px;
      height: 2px;
      background: linear-gradient(90deg, rgba(255, 92, 34, 0.2) 0%, #E52A0D 100%);
    }
  }
}
.pay-content{
  width: 100%;
  margin-top: 25px;
  padding: 0 26px;
  box-sizing: border-box;
  li{
    width: 100%;
    display: flex;
    align-items: center;
    height: 52px;
    .pay_icon{
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    span{
      flex: 1;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(51,51,51,0.6);
      position: relative;
      &::after{
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid rgba(153, 153, 153, 0.3);
      }
      &.active::after{
        background: url('../../../assets/image/mobile/checked_icon.png') no-repeat 50%/cover;
        background-clip: border-box;
        border-color: #7be842;
      }
    }
  }
}
.vipbtn{
  width: 90%;
  margin: 30px auto 0 auto;
  height: 45px;
  text-align: center;
  line-height: 45px;
  border: 1px solid #E52A0D;
  background: #E52A0D;
  border-radius: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.gride_box{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .jiantou{
    position: absolute;
    right: 20px;
    top: 20px;
    width: 74px;
    height: 106px;
  }
  .guide_title{
    width: 100%;
    margin-top: 120px;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    font-size: 20px;
  }
  .browser{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    img{
      width: 78px;
      height: 78px;
      margin: 0 10px;
    }
  }
  .gride-tip{
    width: 100%;
    margin-top: 50px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }
  .gride_img{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 60px auto 0 auto;
    padding:  10px;
    background: #FFFFFF;
    box-shadow: 0 0 6px 0 rgba(51, 51, 51, 5%);
    border-radius: 5px;
    img{
      height: 25px;
    }
  }
}
.markbg{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  .paysuccess{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .paymark{
      width: 280px;
      height: 240px;
      background: #FFFFFF;
      border-radius: 12px;
      padding: 24px 32px 0 32px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .payicon{
        width: 66px;
        height: 73px;
      }
      .payok_text{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
        .gx,.txt{
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #E52A0D;
          line-height: 22px;
        }
        .findtxt{
          margin-top: 8px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(51,51,51,0.86);
          line-height: 21px;
        }
      }
      .payok_btn{
        position: absolute;
        bottom: -18px;
        width: 136px;
        height: 36px;
        background: #E52A0D;
        box-shadow: 0px 4px 7px 0px rgba(255,47,15,0.36);
        border-radius: 20px;
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 21px;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
